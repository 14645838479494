/* Custom toast styles */
.Toastify__toast {
  background-color: #2A3C29 !important;
  color: #ABCDDF !important;
  border: 1px solid #3A4C39 !important;
  border-radius: 0.5rem !important;
  font-family: 'Montserrat', sans-serif !important;
}

.Toastify__toast--success {
  background-color: #2A3C29 !important;
  border-left: 4px solid #E8FC8B !important;
}

.Toastify__toast--error {
  background-color: #2A3C29 !important;
  border-left: 4px solid #ff6b6b !important;
}

.Toastify__toast--info {
  background-color: #2A3C29 !important;
  border-left: 4px solid #4dabf7 !important;
}

.Toastify__toast--warning {
  background-color: #2A3C29 !important;
  border-left: 4px solid #ffd43b !important;
}

.Toastify__close-button {
  color: #ABCDDF !important;
}

.Toastify__progress-bar {
  background: #E8FC8B !important;
}

.Toastify__toast-body {
  color: #ABCDDF !important;
} 