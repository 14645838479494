.App {
  text-align: center;
  display: flex;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.active-scroll-spy {
  font-weight: bold;
}

.growWidth300 {
  animation: growWidth300 2s ease-in-out forwards;

  @media (max-width: 900px) {
    animation: growWidth200 2s ease-in-out forwards;
  }
}

@keyframes growWidth300 {
  from {
    width: 0px;
  }
  to {
    width: 280px;
  }
}

@keyframes growWidth200 {
  from {
    width: 0px;
  }
  to {
    width: 190px;
  }
}

.growWidth100 {
  animation: growWidth100 1s ease-in-out forwards;
}

@keyframes growWidth100 {
  from {
    width: 0px;
  }
  to {
    width: 90px;
  }
}

.growHeight200 {
  animation: growHeight200 1s ease-in-out forwards;
}

@keyframes growHeight200 {
  from {
    height: 0px;
  }
  to {
    height: 200px;
  }
}

.growHeight75 {
  animation: growHeight125 1s ease-in-out forwards;

  @media (max-width: 1008px) {
    animation: growHeight90 1s ease-in-out forwards;
  }
}

@keyframes growHeight125 {
  from {
    height: 0px;
  }
  to {
    height: 125px;
  }
}

@keyframes growHeight90 {
  from {
    height: 0px;
  }
  to {
    height: 90px;
  }
}

.tooltip-inner {
  background-color: #111e12;
  padding: 15px;
}
.tooltip > .arrow:before {
  border-right-color: #111e12;
  border-left-color: #111e12;
}

.tooltip.show {
  opacity: 1;
}

.modal-content {
  background-color: #1d2d1d;
  border: 0px;
}

.modal-header {
  background-color: rgb(17, 30, 18);
  border-bottom: 1px solid #1f3321;
}