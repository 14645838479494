body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111E12;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-item .page-link {
  background: #1d2d1d;
}

.page-item .page-link:hover {
  background: #E9FD8C;
  color: #1d2d1d;
}

.page-item.active .page-link{
  background: white;

  color: #111E12;
  font-weight: bold;
}

.badge-secondary {
  color: #fff;
  background-color: #354236;
}

.btn-primary {
  background-color: #1d2d1d;
  border-color: #1d2d1d;
  color: white;
}

.btn-primary:hover {
  background-color: #E9FD8C;
  border-color: #DAFF99;
  color: #111E12;
}

.btn-primary:active {
  background-color:  #1BB78A;
  border-color: #1BB78A;
  color: white;
}

.btn-primary:focus {
  background-color:  #1BB78A;
  border-color: #1BB78A;
  color: white;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color:  #1BB78A;
  border-color: #1BB78A;
  color: white;
}

.btn-secondary {
  background-color: white;
  border-color: white;
  color: #1d2d1d;
  border: 1px solid #090f08;
}

.btn-secondary:hover {
  background-color: white;
  color: #1BB78A;
}

.btn-secondary:active {
  background-color: white;
  color: #1BB78A;
}

.btn-secondary:focus {
  background-color: white;
  color: #1BB78A;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  background-color: white;
  color: #1BB78A;
}

.dropdown-menu {
  background-color: #1d2d1d;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #1d2d1d;
  text-decoration: none;
  background-color: white;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #1BB78A;
}

.form-control {
  border: 1px solid #090f08;
}

.list-group-item {
  background-color: #1d2d1d;
  border: 1px solid #38633b;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: #1d2d1d;
  border: 1px solid #38633b;
}

@font-face {
  font-family: 'Armstrong';
  src: url('./fonts/Armstrong.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Armstrong';
  src: url('./fonts/Armstrong-Extrabold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 'semi-bold';
  font-style: normal;
}  

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat.woff') format('woff');
  font-weight : normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Light.woff') format('woff');
  font-weight : 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-ExtraBold.woff') format('woff');
  font-weight: 800; /* Corrected from 'extra-bold' to 800 */
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Regular.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}  

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-SemiBold.woff2') format('woff');
  font-weight: 600;
  font-style: normal;
}  

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Black.ttf') format('woff');
  font-weight: 900;
  font-style: black;
}  

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Bold.ttf') format('woff');
  font-weight: 800;
  font-style: bold;
}  

@font-face {
  font-family: 'Denton';
  src: url('./fonts/DentonTextCondTest-Regular.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}  

.navbar-toggler-icon {
  filter: invert(100%) sepia(21%) saturate(0%) hue-rotate(4deg) brightness(108%) contrast(101%);
}

.table td {
  border-bottom: 1px solid #41544c;
}

.table thead th {
  border-bottom: 2px solid #41544c;
  border-top: 2px solid #41544c;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
